import Image from 'next/image';
import Link from 'next/link';
import styled from 'styled-components';
import { Mousewheel, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PaginationOptions } from 'swiper/types/modules/pagination';

import landing2textWrapper from './assets/landing2-text-wrapper.svg';
import SwipeCard from './SwipeCard';

import ImageWithFallback from '@/components/ImageWithFallback';
import LimitedLineText from '@/components/Module/LimitedLineText';
import useViewport from '@/hooks/useViewport';

const SwiperWrapper = styled.div`
  overflow: hidden !important;
`;
const SwiperLeftGradient = styled.div`
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 94.83%);
  z-index: 1;
`;
const SwiperRightGradient = styled.div`
  background: linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0) 94.83%);
  z-index: 1;
`;

export type LandingSection2Props = {
  cards: SwipeCard[];
};

const MobileLandingSection2 = (props: LandingSection2Props) => {
  const { cards } = props;

  return (
    <>
      <div className={`mt-[65px] flex flex-col items-center`}>
        <p
          className={`text-h5 font-bold text-green-200`}
          style={{ textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)` }}
        >
          FEATURED EVENTS
        </p>
        <p
          className={`mt-[16px] text-center font-mont text-[26px] font-extrabold leading-[30px]`}
        >
          SHOWS{` `}
          <br className={``} />
          YOU’LL LOOK BACK
          <br />
          ON SOMEDAY
        </p>
      </div>

      <div className={`relative mt-[32px]`}>
        <SwiperWrapper className={`w-full pl-[24px] pr-[31px] `}>
          <Swiper
            spaceBetween={16}
            slidesPerView={1}
            className={`!overflow-visible`}
            modules={[Pagination]}
            pagination={
              {
                clickable: true,
                horizontalClass: `!w-[calc(100vw-48px)]`,
              } as PaginationOptions
            }
          >
            {cards.map((card) => (
              <SwiperSlide key={card.eventId}>
                <Link href={`/events/${card.eventId}`}>
                  <a>
                    <div
                      className={`mb-[60px] flex flex-col items-center justify-center`}
                    >
                      <ImageWithFallback
                        key={card.imageUrl}
                        placeholder="blur"
                        blurDataURL={card.imageUrl}
                        src={card.imageUrl}
                        width={320}
                        height={240}
                        alt="card image"
                        className={`br-12 aspect-[4/3] rounded-[12px] object-cover`}
                      />
                      <div
                        className={`flex w-full flex-col items-start pt-[16px]`}
                      >
                        <LimitedLineText
                          className={`font-roboto text-b3 font-normal text-gray-200 `}
                          lineCount={1}
                        >
                          {card.venueName}
                        </LimitedLineText>
                        <LimitedLineText
                          className={`mt-[6px] font-roboto text-h5 font-normal `}
                          lineCount={2}
                        >
                          {card.eventTitle}
                        </LimitedLineText>
                        <LimitedLineText
                          className={`mt-[2px] font-roboto text-b2 font-normal text-green-200`}
                          lineCount={1}
                        >
                          {card.itinerary}
                        </LimitedLineText>
                      </div>
                    </div>
                  </a>
                </Link>
              </SwiperSlide>
            ))}
            <SwiperLeftGradient
              className={`absolute left-[-24px] top-0 h-full w-[24px] opacity-50`}
            />
            <SwiperRightGradient
              className={`absolute right-[-31px] top-0 h-full w-[31px] opacity-50`}
            />
          </Swiper>
        </SwiperWrapper>
      </div>
    </>
  );
};

const TabletLandingSection2 = (props: LandingSection2Props) => {
  const { cards } = props;

  return (
    <>
      <div className={`mt-[120px] flex flex-col items-center`}>
        <p
          className={`text-h2 font-bold text-green-200`}
          style={{ textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)` }}
        >
          FEATURED EVENTS
        </p>
        <p
          className={`mt-[40px] text-center font-mont text-[40px] font-extrabold leading-[50px]`}
        >
          SHOWS YOU’LL LOOK BACK
          <br />
          ON SOMEDAY
        </p>
      </div>
      <div className={`relative mt-[100px]`}>
        <SwiperWrapper className={`w-full pl-[24px] pr-[130px] `}>
          <Swiper
            spaceBetween={24}
            slidesPerView={2}
            className={`!overflow-visible`}
            modules={[Pagination]}
            pagination={{
              clickable: true,
            }}
          >
            {cards.map((card) => (
              <SwiperSlide key={card.eventId}>
                <Link href={`/events/${card.eventId}`}>
                  <a>
                    <div
                      className={`mb-[60px] flex flex-col items-center justify-center`}
                    >
                      <ImageWithFallback
                        key={card.imageUrl}
                        placeholder="blur"
                        blurDataURL={card.imageUrl}
                        src={card.imageUrl}
                        width={336}
                        height={336}
                        alt=""
                        className={`br-12 rounded-[12px]`}
                      />
                      <div
                        className={`flex w-full flex-col items-start pt-[16px]`}
                      >
                        <LimitedLineText
                          className={`font-roboto text-b2 font-normal text-gray-200 `}
                          lineCount={1}
                        >
                          {card.venueName}
                        </LimitedLineText>
                        <LimitedLineText
                          className={`mt-[6px] font-roboto text-h4 font-normal `}
                          lineCount={2}
                        >
                          {card.eventTitle}
                        </LimitedLineText>
                        <LimitedLineText
                          className={`mt-[2px] font-roboto text-h4 font-normal text-green-200`}
                          lineCount={1}
                        >
                          {card.itinerary}
                        </LimitedLineText>
                      </div>
                    </div>
                  </a>
                </Link>
              </SwiperSlide>
            ))}
            <SwiperLeftGradient
              className={`absolute left-[-24px] top-0 h-full w-[24px]`}
            />
            <SwiperRightGradient
              className={`absolute right-[-130px] top-0 h-full w-[130px]`}
            />
          </Swiper>
        </SwiperWrapper>
      </div>
    </>
  );
};

const DesktopLandingSection2 = (props: LandingSection2Props) => {
  const { cards } = props;

  return (
    <>
      <div
        className={`relative mt-[146px] flex flex-col items-center pb-[80px]`}
      >
        <div
          className={`relative flex h-[180px] w-[180px] items-center justify-center`}
        >
          <Image
            width={280}
            height={280}
            src={landing2textWrapper}
            alt="landing2-text-wrapper"
          />
          <p
            className={`absolute text-h3 font-bold text-green-200`}
            style={{
              textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)`,
            }}
          >
            FEATURED EVENTS
          </p>
        </div>
        <p
          className={`absolute top-[132px] text-center font-mont text-[50px] font-extrabold leading-[64px]`}
        >
          SHOWS YOU’LL LOOK BACK
          <br />
          ON SOMEDAY
        </p>
      </div>
      <div className={`relative mt-[80px] flex justify-center`}>
        <SwiperWrapper className={`w-full px-[12.5%]`}>
          <Swiper
            spaceBetween={32}
            mousewheel={{
              forceToAxis: true,
            }}
            speed={250}
            slidesPerView={4}
            navigation={true}
            className={``}
            modules={[Navigation, Mousewheel]}
          >
            {cards.map((card) => (
              <SwiperSlide key={card.eventId}>
                <Link href={`/events/${card.eventId}`}>
                  <a>
                    <div
                      className={`flex flex-col items-center justify-center`}
                    >
                      <ImageWithFallback
                        key={card.imageUrl}
                        placeholder="blur"
                        blurDataURL={card.imageUrl}
                        src={card.imageUrl}
                        width={336 * 3}
                        height={336 * 3}
                        alt="card"
                        className={`br-12 w-full rounded-[12px]`}
                      />
                      <div
                        className={`mt-[20px] flex w-full flex-col items-start`}
                      >
                        <LimitedLineText
                          className={`font-roboto text-b2 font-normal text-gray-200`}
                          lineCount={1}
                        >
                          {card.venueName}
                        </LimitedLineText>
                        <LimitedLineText
                          className={`mt-[2px] font-roboto text-h4 font-normal`}
                          lineCount={2}
                        >
                          {card.eventTitle}
                        </LimitedLineText>
                        <LimitedLineText
                          className={`mt-[2px] font-roboto text-h4 font-normal text-green-200`}
                          lineCount={1}
                        >
                          {card.itinerary}
                        </LimitedLineText>
                      </div>
                    </div>
                  </a>
                </Link>
              </SwiperSlide>
            ))}
            <SwiperLeftGradient
              className={`absolute top-0 left-[-10px] h-full w-[9%]`}
            />
            <SwiperRightGradient
              className={`absolute  top-0 right-[-10px] h-full w-[9%]`}
            />
          </Swiper>
        </SwiperWrapper>
      </div>
    </>
  );
};

const LandingSection2 = (props: LandingSection2Props) => {
  const { cards } = props;
  const viewport = useViewport();

  if (cards.length === 0) {
    return null;
  }

  if (viewport == `mobile`) {
    return <MobileLandingSection2 {...props} />;
  }
  if (viewport == `tablet`) {
    return <TabletLandingSection2 {...props} />;
  }
  if (viewport == `desktop`) {
    return <DesktopLandingSection2 {...props} />;
  }

  return null;
};

export default LandingSection2;

import Image from 'next/image';
import Link from 'next/link';

import landing4 from './assets/landing4.png';

import PrimaryButton from '@/components/Module/PrimaryButton';
import useViewport from '@/hooks/useViewport';

export type LandingSection4Props = {};

const MobileLandingSection4 = (props: LandingSection4Props) => {
  const {} = props;

  return (
    <div className={`relative mt-[71px] flex w-full flex-col`}>
      <Image
        width={563 * 2}
        height={1001 * 2}
        src={landing4}
        objectFit={`cover`}
        alt="landing4-mobile"
        className={`w-full`}
      />
      <div className={`absolute top-[92px] flex w-full flex-col items-center`}>
        <p
          className={`text-center font-mont text-[26px] font-extrabold leading-[30px]`}
        >
          CUT THROUGH THE <br /> NOISE
        </p>
        <p
          className={`mt-[18px] text-center font-roboto text-[16px] font-normal leading-[22px] tracking-tight text-gray-500`}
          style={{ textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)` }}
        >
          From check-out to check-in, Someday helps get <br /> you to the
          experiences that matter the most
        </p>
        <div className={`mt-[48px] w-[238px]`}>
          <Link href={`/search`}>
            <a>
              <PrimaryButton>START SEARCHING</PrimaryButton>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const TabletLandingSection4 = (props: LandingSection4Props) => {
  const {} = props;

  return (
    <div className={`relative flex w-full flex-col`}>
      <Image
        width={850 * 2}
        height={667 * 2}
        objectFit={`cover`}
        src={landing4}
        alt="landing4-desktop"
      />
      <div className={`absolute top-[79px] flex w-full flex-col items-center`}>
        <p
          className={`text-center font-mont text-[40px] font-extrabold leading-[50px]`}
        >
          CUT THROUGH THE NOISE
        </p>
        <p
          className={`mt-[13px] text-center font-roboto text-[16px] font-normal leading-[22px] tracking-normal text-gray-500`}
          style={{ textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)` }}
        >
          From check-out to check-in, Someday helps get <br /> you to the
          experiences that matter the most
        </p>
        <div className={`mt-[48px] w-[272px]`}>
          <Link href={`/search`}>
            <a>
              <PrimaryButton>START SEARCHING</PrimaryButton>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const DesktopLandingSection4 = (props: LandingSection4Props) => {
  const {} = props;

  return (
    <div className={`relative mt-[135px]`}>
      <Image
        width={3840 * 2}
        height={2164 * 2}
        src={landing4}
        alt="landing4-desktop"
      />
      <div
        className={`absolute top-[20%] left-0 flex w-full flex-col items-center`}
      >
        <p
          className={`text-center font-mont text-[50px] font-extrabold leading-[68px]`}
          style={{
            textShadow: `0px 0px 30px rgba(191, 191, 191, 0.12)`,
          }}
        >
          CUT THROUGH THE NOISE
        </p>
        <p
          className={`mt-[32px] text-center text-[20px] font-normal leading-[26px] tracking-tight text-gray-500`}
          style={{
            textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)`,
          }}
        >
          From check-out to check-in, Someday helps get <br /> you to the
          experiences that matter the most
        </p>
        <div className={`mt-[56px] w-[327px]`}>
          <Link href={`/search`}>
            <a>
              <PrimaryButton>START SEARCHING</PrimaryButton>
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
};

const LandingSection4 = (props: LandingSection4Props) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileLandingSection4 {...props} />;
  }
  if (viewport == `tablet`) {
    return <TabletLandingSection4 {...props} />;
  }
  if (viewport == `desktop`) {
    return <DesktopLandingSection4 {...props} />;
  }

  return null;
};

export default LandingSection4;

import LandingSection1 from '@/components/Landing/LandingSection1';
import LandingSection2 from '@/components/Landing/LandingSection2';
import SwipeCard from '@/components/Landing/LandingSection2/SwipeCard';
import LandingSection3 from '@/components/Landing/LandingSection3';
import LandingSection4 from '@/components/Landing/LandingSection4';

export type LandingProps = {
  cards: SwipeCard[];
};

const Landing = (props: LandingProps) => {
  const { cards } = props;

  return (
    <div className={`flex w-full flex-col`}>
      <LandingSection1 />
      <LandingSection2 cards={cards} />
      <LandingSection3 />
      <LandingSection4 />
    </div>
  );
};

export default Landing;

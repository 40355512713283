import Image from 'next/image';
import styled from 'styled-components';

import landing1desktopGradientCircle from './assets/landing1-desktop-gradient-circle.svg';
import landing1desktopIcon from './assets/landing1-desktop-icon.svg';
import landing1desktop from './assets/landing1-desktop.png';
import landing1mobile from './assets/landing1-mobile.png';
import landing1Tablet from './assets/landing1-tablet.png';

import useViewport from '@/hooks/useViewport';

export type LandingSection1Props = {};

const Highlight = styled.span`
  background: linear-gradient(90deg, #00f5a0 0%, #00d9f5 100%);
  -webkit-text-fill-color: transparent;
  //noinspection CssInvalidPropertyValue
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`;

const MobileLandingSection1 = (props: LandingSection1Props) => {
  const {} = props;

  return (
    <div className={`relative flex flex-col items-center`}>
      <Image
        width={563 * 2}
        height={848 * 2}
        src={landing1mobile}
        alt="landing1-mobile"
      />
      <p
        className={`absolute top-[72px] text-center font-mont text-[36px] font-extrabold leading-[40px]`}
      >
        <Highlight>SOMEDAY</Highlight>
        {` `}IS
        <br /> JUST <br /> AROUND <br /> THE CORNER
      </p>
    </div>
  );
};

const TabletLandingSection1 = (props: LandingSection1Props) => {
  const {} = props;

  return (
    <div className={`relative flex flex-col items-center`}>
      <Image
        width={850 * 2}
        height={1096 * 2}
        src={landing1Tablet}
        alt="landing1-tablet"
      />
      <p
        className={`absolute top-[184px] text-center font-mont text-[40px] font-extrabold leading-[50px]`}
      >
        <Highlight>SOMEDAY</Highlight>
        {` `}IS
        <br /> JUST <br /> AROUND <br /> THE CORNER
      </p>
    </div>
  );
};

const DesktopLandingSection1 = (props: LandingSection1Props) => {
  const {} = props;

  return (
    <div className={`relative flex aspect-[16/9] flex-col`}>
      <div className={`flex flex-row-reverse justify-between`}>
        <div className={`relative w-[50%]`}>
          <Image
            src={landing1desktop}
            alt="landing1-desktop"
            width={1920 * 2}
            height={2160 * 2}
          />
          <div
            style={{
              position: `absolute`,
              background: `linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 94.83%)`,
              transform: `rotate(-180deg)`,
              height: `340px`,
              width: `100%`,
              bottom: 0,
            }}
          />
        </div>
        <div className={`relative h-[40%] w-[30%]`}>
          <Image
            src={landing1desktopGradientCircle}
            alt="landing1-desktop-gradient"
            width={714}
            height={609}
          />
        </div>
      </div>
      <div className={`absolute top-[34%] left-[19%]`}>
        <div className="absolute top-[31px] left-[240px] w-full">
          <Image
            src={landing1desktopIcon}
            alt="landing1-desktop-icon"
            width={280}
            height={280}
          />
        </div>
        <p
          className={`relative font-mont text-[50px] font-extrabold leading-[68px]`}
          style={{
            textShadow: `0px 0px 30px rgba(191, 191, 191, 0.12)`,
          }}
        >
          SOMEDAY IS
          <br /> JUST AROUND <br /> THE CORNER
        </p>
      </div>
    </div>
  );
};

const LandingSection1 = (props: LandingSection1Props) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileLandingSection1 {...props} />;
  }
  if (viewport == `tablet`) {
    return <TabletLandingSection1 {...props} />;
  }
  if (viewport == `desktop`) {
    return <DesktopLandingSection1 {...props} />;
  }

  return null;
};

export default LandingSection1;

import { captureException } from '@sentry/nextjs';
import { ImageProps } from 'next/dist/client/image';
import Image from 'next/image';
import { useState } from 'react';

import fallbackImage from '@/assets/music.svg';

type ImageWithFallbackProps = ImageProps & {
  key: string | number;
};

const ImageWithFallback = (props: ImageWithFallbackProps) => {
  const { src, alt, ...others } = props;
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <Image
      {...others}
      blurDataURL={props.blurDataURL || fallbackImage}
      alt={alt}
      src={imgSrc || fallbackImage}
      onErrorCapture={() => {
        captureException(`error while loading ${src}`);
        setImgSrc(fallbackImage);
      }}
    />
  );
};

export default ImageWithFallback;

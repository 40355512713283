import styled from 'styled-components';

const LimitedLineText = styled.div<{ lineCount: number }>`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineCount};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default LimitedLineText;

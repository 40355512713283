import Image from 'next/image';
import Link from 'next/link';

import landing3desktop from './assets/landing3-desktop.png';
import landing3mobile from './assets/landing3-mobile.png';
import landing3Tablet from './assets/landing3-tablet.png';

import PrimaryButton from '@/components/Module/PrimaryButton';
import useViewport from '@/hooks/useViewport';

export type LandingSection3Props = {};

const MobileLandingSection3 = (props: LandingSection3Props) => {
  const {} = props;

  return (
    <div className={`relative flex w-full flex-col justify-center pb-[250px]`}>
      <div className="relative top-[250px] aspect-[563/670] w-full">
        <Image
          width={563 * 2}
          height={670 * 2}
          src={landing3mobile}
          alt="landing3-mobile"
        />
      </div>
      <div className={`absolute top-[142px] flex w-full flex-col items-center`}>
        <p
          className={`text-center font-mont text-[26px] font-extrabold leading-[30px]`}
        >
          BUILT FOR THE
          <br />
          INDEPENDENT
        </p>
        <p
          className={`mt-[14px] text-center text-[16px] font-normal leading-[22px] tracking-tight text-gray-200 `}
          style={{
            textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)`,
          }}
        >
          Laser-focused on the communities of artists
          <br />
          and fans that thrive around independent clubs
        </p>
        <div className={`mt-[40px] flex w-full justify-center `}>
          <div className={`w-[168px]`}>
            <Link href={`/my-profile`}>
              <a>
                <PrimaryButton>JOIN US</PrimaryButton>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const TabletLandingSection3 = (props: LandingSection3Props) => {
  const {} = props;

  return (
    <div className={`relative flex w-full flex-col justify-center pb-[340px]`}>
      <div className="relative top-[340px] w-full">
        <Image
          width={850 * 2}
          height={884 * 2}
          src={landing3Tablet}
          alt="landing3-tablet"
        />
      </div>
      <div className={`absolute top-[150px] flex w-full flex-col items-center`}>
        <p
          className={`text-center font-mont text-[40px] font-extrabold leading-[50px]`}
        >
          BUILT FOR THE
          <br />
          INDEPENDENT
        </p>
        <p
          className={`mt-[16px] text-center text-[16px] font-normal leading-[22px] tracking-tight text-gray-200 `}
          style={{
            textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)`,
          }}
        >
          Laser-focused on the communities of artists
          <br />
          and fans that thrive around independent clubs
        </p>
        <div className={`mt-[56px] flex w-full justify-center `}>
          <div className={`w-[168px]`}>
            <Link href={`/my-profile`}>
              <a>
                <PrimaryButton>JOIN US</PrimaryButton>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const DesktopLandingSection3 = (props: LandingSection3Props) => {
  const {} = props;

  return (
    <div className={`relative flex aspect-[1920/1354] flex-col`}>
      <Image
        width={1920 * 3}
        height={1354 * 3}
        src={landing3desktop}
        alt="landing3-desktop"
      />
      <div
        className={`absolute left-[62%] bottom-[30%] mt-0 flex flex-col items-center self-start`}
      >
        <p
          className={`text-left font-mont text-[50px] font-extrabold leading-[68px]`}
          style={{
            textShadow: `0px 0px 30px rgba(191, 191, 191, 0.12)`,
          }}
        >
          BUILT FOR THE
          <br />
          INDEPENDENT
        </p>
        <p
          className={`mt-[20px] text-left text-[20px] font-normal leading-[26px] tracking-tight text-gray-200`}
          style={{
            textShadow: `0px 0px 20px rgba(26, 193, 181, 0.16)`,
          }}
        >
          Laser-focused on the communities of artists
          <br />
          and fans that thrive around independent clubs
        </p>
        <div
          className={`relative top-[10px] mt-[50px] flex w-full justify-start p-[0px]`}
        >
          <div className={`w-[168px]`}>
            <Link href={`/my-profile`}>
              <a>
                <PrimaryButton>JOIN US</PrimaryButton>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const LandingSection3 = (props: LandingSection3Props) => {
  const {} = props;
  const viewport = useViewport();

  if (viewport == `mobile`) {
    return <MobileLandingSection3 {...props} />;
  }
  if (viewport == `tablet`) {
    return <TabletLandingSection3 {...props} />;
  }
  if (viewport == `desktop`) {
    return <DesktopLandingSection3 {...props} />;
  }

  return null;
};

export default LandingSection3;
